import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppAppBar from './components/AdminBar';
import getLPTheme from './getLPTheme';
import { db } from './firebase-config';
import { setDoc, doc,  } from 'firebase/firestore';
import { storage } from './firebase-config2';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
const SimpleForm = () => {
  const [mode, setMode] = useState('light');
  const LPtheme = createTheme(getLPTheme(mode));
  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const [uploadedFiles, setUploadedFiles] = useState({
    logofile: false,
  });
  const [formData, setFormData] = useState({
    logofile:null
  });
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       const querySnapshot = await getDoc(doc(db, 'Mainheading'));
//       const data = querySnapshot.data();
//       setTableData(data);
//     };

//     fetchData();
//   }, []);
const uploadImageToFirebase = async (file, folderName, code) => {
    try {
      const productCodeImage = code;
      const uploadPath = `${folderName}/${productCodeImage}+${"logo"}`;
      const storageRef = ref(storage, uploadPath);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          null,
          (error) => {
            console.error('Error uploading image:', error.message);
            reject(error.message);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve({
                url: downloadURL,
                path: uploadPath,
              });
            });
          }
        );
      });
    } catch (error) {
      console.error('Error uploading image:', error.message);
      throw error;
    }
  };
  const handleChange = (e) => {
    const { name,files, value } = e.target;
    setFormData({
        ...formData,
        [name]: files ? files[0] : value
      });
  
      if (files) {
        setUploadedFiles((prev) => ({
          ...prev,
          [name]: true
        }));
      }
  
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const productCode = "Logo";
    try {
        let data=formData
        if (formData.logofile) {
            data.logofile = await uploadImageToFirebase(formData.logofile, 'Logo',productCode);
          }
          else{
            alert("upload a logo")
            setLoading(false);
            return
          }
      await setDoc(doc(db, 'Logo', productCode), {
        ...data,
        code: productCode
      });
      setTableData([...tableData, { ...formData, code: productCode }]);
      setFormData({ logofile: null});
      setUploadedFiles({logofile:false})
    } catch (error) {
      console.error('Error submitting form:', error.message);
    } finally {
      setLoading(false);
    }
  };


  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Container
        id="form"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, filter: loading ? 'blur(5px)' : 'none' }}>
          <Typography variant="h6" gutterBottom>
          Change Logo
          </Typography>
          <Grid container spacing={2}>
       
            <Grid item xs={12} sm={12}>
            <Button
              variant="contained"
              component="label"
              fullWidth
              color={uploadedFiles.logofile ? 'success' : 'primary'}
            >
              {uploadedFiles.logofile ? ' Uploaded New Logo' : 'Upload New Logo'}
              <input
                type="file"
                hidden
                name="logofile"
                onChange={handleChange}
              />
            </Button>
          </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SimpleForm;
