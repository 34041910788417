import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyBDb3ruFLpWzrEr8CsDpSYzNY-nGQ9ZKm0",
  authDomain: "almairacommunityloan.firebaseapp.com",
  projectId: "almairacommunityloan",
  storageBucket: "almairacommunityloan.appspot.com",
  messagingSenderId: "773589904815",
  appId: "1:773589904815:web:5363df542d5bdad7b8535a",
  measurementId: "G-00GZ6Q3VN0"
};


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);