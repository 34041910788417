import React,{useState,  useEffect }  from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Form from './components/Form';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import getLPTheme from './getLPTheme';
import { db } from './firebase-config';
import { collection, getDocs, doc,getDoc  } from 'firebase/firestore';




export default function LandingPage() {
  const [mode, setMode] = useState('light');
  const [faqs, setFaqs] = useState([]);
  const [features, setFeatures] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [mainheading, setMainheading] = useState([]);
  const [phone, setPhone] = useState(null);
  const LPtheme = createTheme(getLPTheme(mode));
  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

useEffect(()=>{
  const fetchData = async () => {
    const querySnapshot = await getDocs(collection(db, 'Faq'));
    const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setFaqs(data);
  };
  const fetchData1 = async () => {
    const querySnapshot = await getDocs(collection(db, 'Features'));
    const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setFeatures(data);
  };
  const fetchData2 = async () => {
    const querySnapshot = await getDocs(collection(db, 'Testimonials'));
    const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setTestimonials(data);
  };
  const fetchData3 = async () => {
    const querySnapshot = await getDoc(doc(db, 'Mainheading',"mainheading"));
    const data = querySnapshot.data();
    setMainheading(data);
  };

  const fetchData4 = async () => {
    const querySnapshot = await getDoc(doc(db, 'Phone',"Phone"));
    const data = querySnapshot.data();
    setPhone(data.name);
  };

  fetchData();
  fetchData1();
  fetchData2();
  fetchData3();
  fetchData4();
},[])
  return (
    <ThemeProvider theme={ LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero mainheading={mainheading} phone={phone} />
      <Box sx={{ bgcolor: 'background.default' }}>
        <Features features={features}/>
        <Divider />
        <Testimonials testimonials={testimonials} />
        <Divider />
        <FAQ faq={faqs} />
        <Divider />
        <Form/>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
