import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppAppBar from './components/AdminBar';
import getLPTheme from './getLPTheme';
import { v4 as uuidv4 } from 'uuid';
import { db } from './firebase-config';
import { setDoc, collection, getDocs, doc, deleteDoc } from 'firebase/firestore';

const SimpleForm = () => {
  const [mode, setMode] = useState('light');
  const LPtheme = createTheme(getLPTheme(mode));
  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    occupation:''
  });
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, 'Testimonials'));
      const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setTableData(data);
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const generateCode = () => {
    return `${uuidv4()}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const productCode = generateCode();
    try {
      await setDoc(doc(db, 'Testimonials', productCode), {
        ...formData,
        code: productCode
      });
      setTableData([...tableData, { ...formData, code: productCode }]);
      setFormData({ name: '', description: '',occupation:'' });
    } catch (error) {
      console.error('Error submitting form:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (code) => {
    setLoading(true);
    try {
      await deleteDoc(doc(db, 'Testimonials', code));
      setTableData(tableData.filter((item) => item.code !== code));
    } catch (error) {
      console.error('Error deleting document:', error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Container
        id="form"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, filter: loading ? 'blur(5px)' : 'none' }}>
          <Typography variant="h6" gutterBottom>
          Testimonials Form
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                name="name"
                label="Name"
                fullWidth
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="occupation"
                label="Occupation"
                fullWidth
                value={formData.occupation}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="description"
                label="Answer"
                fullWidth
                multiline
                rows={4}
                value={formData.description}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
        <TableContainer component={Paper} sx={{ mt: 3, width: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Occupation</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.occupation}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDelete(row.code)} color="secondary">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </ThemeProvider>
  );
};

export default SimpleForm;
