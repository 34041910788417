import logo from './logo.svg';
import './App.css';
import LandingPage from "./LandingPage"
import SignIn from './SignInPage';
import Features from './Features';
import Faq from "./Faq"
import Testimonials from './Testimonials';
import Mainheading from "./Mainheading";
import Applicants from "./Applicants"
import Wattsapp from "./Wattsapp"
import Logo from "./Changelogo"

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/allapplicants" element={<LandingPage />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/features" element={<Features />} />
      <Route path="/testimonials" element={<Testimonials />} />
      <Route path="/mainheading" element={<Mainheading />} />
      <Route path="/applicants" element={<Applicants />} />
      <Route path="/wattsapp" element={<Wattsapp />} />
      <Route path="/logo" element={<Logo />} />
      
</Routes>
</Router>
  );
}

export default App;
