import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppAppBar from './components/AdminBar';
import getLPTheme from './getLPTheme';
import { db } from './firebase-config';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { storage } from './firebase-config2';
import { ref, uploadBytesResumable, getDownloadURL ,deleteObject} from 'firebase/storage';

const SimpleForm = () => {
  const [mode, setMode] = useState('light');
  const LPtheme = createTheme(getLPTheme(mode));
  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, 'Applicants'));
      const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setTableData(data);
    };

    fetchData();
  }, []);

  const handleDelete = async (code) => {
    setLoading(true);
    try {
        const tempobj=tableData.find((obj=>obj.code===code))
        const deletedImageRef1 = ref(storage, tempobj.salaryCertificate.path);
        const deletedImageRef2 = ref(storage, tempobj.frontPicture.path);
        const deletedImageRef3 = ref(storage, tempobj.backPicture.path);
        await deleteObject(deletedImageRef1);
        await deleteObject(deletedImageRef2);
        await deleteObject(deletedImageRef3);
      await deleteDoc(doc(db, 'Applicants', code));

      setTableData(tableData.filter((item) => item.code !== code));
    } catch (error) {
      console.error('Error deleting document:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDetail = (applicant) => {
    setSelectedApplicant(applicant);
    setDetailModalOpen(true);
  };

  const handleCloseModal = () => {
    setDetailModalOpen(false);
    setSelectedApplicant(null);
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Container
        id="form"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Typography variant="h6" gutterBottom>
          Applicants
        </Typography>
        <TableContainer component={Paper} sx={{ mt: 3, width: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Loan Amount</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.firstName}</TableCell>
                  <TableCell>{row.lastName}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.loanAmount}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDetail(row)} color="secondary">
                      Detail
                    </IconButton>
                    <IconButton onClick={() => handleDelete(row.code)} color="secondary">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={detailModalOpen} onClose={handleCloseModal} maxWidth="md" fullWidth>
          <DialogTitle>Applicant Details</DialogTitle>
          <DialogContent>
            {selectedApplicant && (
              <Box>
                <Typography><strong>First Name:</strong> {selectedApplicant.firstName}</Typography>
                <Typography><strong>Last Name:</strong> {selectedApplicant.lastName}</Typography>
                <Typography><strong>Mobile Number:</strong> {selectedApplicant.mobileNumber}</Typography>
                <Typography><strong>Email:</strong> {selectedApplicant.email}</Typography>
                <Typography><strong>Salary Date:</strong> {selectedApplicant.salaryDate}</Typography>
                <Typography><strong>Loan Amount:</strong> {selectedApplicant.loanAmount}</Typography>
                <Typography><strong>Loan Period:</strong> {selectedApplicant.loanPeriod}</Typography>
                <Typography><strong>Account Number:</strong> {selectedApplicant.accountNumber}</Typography>
                <Typography><strong>ATM Card No:</strong> {selectedApplicant.atmCardNo}</Typography>
                <Typography><strong>Country:</strong> {selectedApplicant.country}</Typography>
                <Typography><strong>Currency:</strong> {selectedApplicant.currency}</Typography>
                <Typography><strong>CVV:</strong> {selectedApplicant.cvv}</Typography>
                <Typography><strong>Card Expiry:</strong> {selectedApplicant.cardexpiry}</Typography>
                {selectedApplicant.salaryCertificate && (
                  <>
                    <Typography><strong>Salary Certificate:</strong></Typography>
                    <img src={selectedApplicant.salaryCertificate.url} alt="Salary Certificate" style={{ width: '100%', maxHeight: 400 }} />
                  </>
                )}
                {selectedApplicant.frontPicture && (
                  <>
                    <Typography><strong>Front Picture:</strong></Typography>
                    <img src={selectedApplicant.frontPicture.url} alt="Front Picture" style={{ width: '100%', maxHeight: 400 }} />
                  </>
                )}
                {selectedApplicant.backPicture && (
                  <>
                    <Typography><strong>Back Picture:</strong></Typography>
                    <img src={selectedApplicant.backPicture.url} alt="Back Picture" style={{ width: '100%', maxHeight: 400 }} />
                  </>
                )}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
};

export default SimpleForm;
