import React,{useState} from 'react';
import SignIn from './components/SignIn'; // Adjust the path as necessary

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import getLPTheme from './getLPTheme';
function App() {
  const [mode, setMode] = useState('light');
  const LPtheme = createTheme(getLPTheme(mode));
  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };
  return (
    
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <SignIn />
      </ThemeProvider>
    
  );
}

export default App;
